class CustomPopup {
    constructor(options) {
        this.position = options.position;
        this.template = options.template;
        this.content = options.content;
        this.map = options.map;

        var bubbleAnchor = this.fillTemplate({
            template: this.template,
            content: this.content
        });

        this.containerDiv = document.createElement('div');
        this.containerDiv.classList.add('popup-container');
        this.containerDiv.innerHTML = bubbleAnchor;

        // Optionally stop clicks, etc., from bubbling up to the map.
        google.maps.OverlayView.preventMapHitsAndGesturesFrom(this.containerDiv);

        this.overlay = null;

        return this.createPopup();
    }

    createPopup() {
        this.overlay = new google.maps.OverlayView();
        this.overlay.setMap(this.map);
        this.overlay.onAdd = () => this.onAdd();
        this.overlay.onRemove = () => this.onRemove();
        this.overlay.draw = () => this.draw();
        this.overlay.open = () => this.open();
        this.overlay.close = () => this.close();

        return this.overlay;
    }

    onAdd() {
        this.overlay.getPanes().floatPane.appendChild(this.containerDiv);
    }
    onRemove() {
        if (this.containerDiv.parentElement) {
            this.containerDiv.parentElement.removeChild(this.containerDiv);
        }
    }

    open() {
        this.containerDiv.classList.add('opened');
    }

    close() {
        this.containerDiv.classList.remove('opened');
    }

    draw() {
        var divPosition = this.overlay.getProjection().fromLatLngToDivPixel(this.position);

        // Hide the popup when it is far out of view.
        var display =
            Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ?
                'block' :
                'none';

        if (display === 'block') {
            this.containerDiv.style.left = divPosition.x + 'px';
            this.containerDiv.style.top = divPosition.y + 'px';
        }
        if (this.containerDiv.style.display !== display) {
            this.containerDiv.style.display = display;
        }
    }

    fillTemplate(options) {

        let template = document.getElementById(options.template).innerHTML;
        let content = options.content;

        for(const property in content) {
            template = template.replace('<%'+ property +'%>', content[property]);
        }

        return template;
    }


}

export default (
    CustomPopup
);
