class CurrentPin {
    constructor(options) {
        this.overlay = null;
        this.latlng = options.latlng;
        this.map = options.map;

        return this.createPin();
    }

    createPin() {
        this.overlay = new google.maps.OverlayView();
        this.overlay.setMap(this.map);
        this.overlay.draw = () => this.draw();
        this.overlay.onRemove = () => this.onRemove();
        this.overlay.getPosition = () => this.getPosition();

        return this.overlay;
    }

    createDiv() {
        this.div = document.createElement('div');
        this.className = 'marker';

        this.div.classList.add(this.className);
        this.div.style.position = 'absolute';


        this.div.innerHTML = '<div class="current-pin"></div>';
    }

    appendDivToOverlay() {
        const panes = this.overlay.getPanes();
        panes.overlayImage.appendChild(this.div);
    }

    positionDiv() {
        const point = this.overlay.getProjection().fromLatLngToDivPixel(this.latlng);

        if (point) {
            this.div.style.left = point.x + 'px';
            this.div.style.top = point.y + 'px';
        }
    }

    draw() {
        if (!this.div) {
            this.createDiv();
            this.appendDivToOverlay();
        }
        this.positionDiv();
    }

    onRemove() {
        if (this.div) {
            this.div.parentNode.removeChild(this.div);
            this.div = null;
        }
    }

    getPosition() {
        return this.latlng;
    }
}

export default (
    CurrentPin
);
