import GoogleMap from "../components/map/_map";
import {mapStyle} from "../components/map/include/_mapStyle";
import {mergeObjects, getClosest} from "dauphine-js";
import CustomPin from "../components/map/_custom-pin";
import CurrentPin from "../components/map/_current-pin"
import CustomPopup from "../components/map/_custom-popup";
import {markerclusterSettings} from "../components/map/include/_markerclustersSettings";
import initMasonry from "../components/_masonry";
import {setInputRange } from "../components/map/_map-range";

/**
 * Initialisations des variables communes
 * map = GoogleMap
 * markers = [marker]
 * markerClusterer = cluster de markers
 * datas = {data}
 * bounds = google.maps.LatLngBounds;
 */
let map, markers, markerClusterer, datas, bounds;

/**
 * Centre de la France
 * @type {{lng: number, lat: number}}
 */
const defaultCenter = {lat: 46.8558587378054, lng : 2.837578640427713}

/**
 * Niveau de zoom par défaut
 * @type {number}
 */
const defaultZoom = 6;

/**
 * Géolocalisation
 */
export const geoFindMe = () => {
    const status = document.getElementById('status');
    const programMap = document.getElementById('program-map')
    const regionInput = document.getElementById('region');
    const latInput = document.getElementById('lat');
    const lngInput = document.getElementById('lng');
    const mapRange = document.querySelector('[js-map-range]')
    const findMe = document.querySelector('[js-find-me]');
    let inputEvent = new Event('change', { bubbles: true });

    function success(position) {
        const latitude  = position.coords.latitude;
        const longitude = position.coords.longitude;
        const latlng = new google.maps.LatLng(latitude, longitude);
        const geocoder = new google.maps.Geocoder();

        geocoder
            .geocode({ location: latlng })
            .then((response) => {
                if (response.results[0].address_components[2].short_name) {

                    if (programMap) {
                        map.setZoom(9);

                        const marker = new CurrentPin({
                            latlng: latlng,
                            map: map,
                        });

                        map.panTo(marker.getPosition())
                        markers.push(marker);
                    }

                    setTimeout(function() {
                        regionInput.value = response.results[0].address_components[2].short_name;
                        regionInput.setAttribute('value', response.results[0].address_components[2].short_name);
                        regionInput.dispatchEvent(inputEvent);
                    }, 200)

                    latInput.value = latitude;
                    latInput.setAttribute('value',latitude);
                    latInput.dispatchEvent(inputEvent);

                    lngInput.value = longitude;
                    lngInput.setAttribute('value', longitude);
                    lngInput.dispatchEvent(inputEvent);

                    setInputRange();

                    status.textContent = '';

                    if (! findMe.classList.contains('hide')) {
                        findMe.classList.add('hide');
                    }
                    if (! mapRange.classList.contains('show')) {
                        mapRange.style.display = "block";
                        setTimeout(() => {
                            mapRange.classList.add('show');
                        }, 250)
                    }

                } else {
                    window.alert("Aucun résultat trouvé\n");
                }
            })
            .catch((e) => window.alert("Le géocodeur a échoué en raison de : " + e));
    }

    function error() {
        status.textContent = 'Impossible de récupérer votre position';
    }

    if (!navigator.geolocation) {
        status.textContent = 'La géolocalisation n\'est pas supportée par votre navigateur';
    } else {
        status.textContent = 'Localisation…';
        navigator.geolocation.getCurrentPosition(success, error);
    }
}

/**
 * Initialisation de la map
 * @param options
 */
export const initStoreLocator = options => {
    const defaultOptions = {
        mapContainerSelector : '#program-map',
        center : defaultCenter,
        address : '',
        streetViewControl: false,
        styles: mapStyle
    }

    const config = mergeObjects(defaultOptions, options ? options : {});

    map = new GoogleMap(config);

    datas = initDatas;

    renderStoreLocator();
}

/**
 * Rendu de la map
 */
export const renderStoreLocator = () => {
    removeMarkers();
    setMarkers(datas)
}

/**
 * Met à jour les données
 * @param newDatas
 */
export const updateData = newDatas => {
    datas = newDatas
    renderStoreLocator();
}

/**
 * Gère la création des markers
 * @param data
 */
export const setMarkers = data => {
    if (!data) {
        return;
    }

    bounds = new google.maps.LatLngBounds();

    markers = data.map(function (location) {
        if (location.head.city) {
            const programName = location.head.name ? location.head.name : '';

            const coords = new google.maps.LatLng(location.head.city.lat, location.head.city.lng);

            const marker = new CustomPin({
                latlng: coords,
                map: map,
                html: document.getElementById('template-store-pin').innerHTML,
                customCenterCoords: {x: 22.5, y: 46}
            });

            //On ajoute une propriété ID qui va nous permettre d'associer les markers et les cards
            marker['ID'] = location.ID;

            //On étend les bounds avec les coordonnées du marker
            bounds.extend(coords);

            let image = "";
            if (location.head.main_image) {
                image = location.head.main_image.url;
            }

            const popup = new CustomPopup({
                position : coords,
                template : 'template-pop-up',
                content : {
                    title : programName,
                    address : location.head.city.address,
                    image : image,
                    zoom : 'https://www.google.com/maps/search/?api=1&amp;query='+location.head.city.lat+','+location.head.city.lng+'&amp;query_place_id='+location.head.city.place_id,
                    route : 'https://www.google.fr/maps/dir/'+encodeURIComponent(location.head.city.address)+'/',
                    permalink : location.permalink,
                },
                map : map,
            });

            marker.addListener('click', function () {
                map.panTo(marker.getPosition());
                popup.open(map, marker);
            });

            document.addEventListener('click', function (event) {
                if (event.target.closest('.' + marker.getClassName()) === null) {
                    popup.close();
                }
            });

            return marker;
        }
    });

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let boundsOffset = 0.006;
    let boundsCenter = bounds.getCenter();

    if (urlParams.get('lat') && urlParams.get('lat') !== 'all' && urlParams.get('lat') !== 'null' && urlParams.get('lng') && urlParams.get('lng') !== 'all' && urlParams.get('lng') !== 'null') {
        const searchedCenter = new google.maps.LatLng(urlParams.get('lat'), urlParams.get('lng'));
        boundsOffset = 0.08;
        boundsCenter = searchedCenter;
    }
    //Ici j'ajoute un point au nord-est et un au sud-ouest du centre des bounds pour forcer un niveau de zoom minimum
    bounds.extend(new google.maps.LatLng(boundsCenter.lat() + boundsOffset, boundsCenter.lng() + boundsOffset));
    bounds.extend(new google.maps.LatLng(boundsCenter.lat() - boundsOffset, boundsCenter.lng() - boundsOffset));

    //On ajuste les bounds pour couvrir tous les markers, puis on met le centre de la map au centre des markers
    map.fitBounds(bounds);
    map.setCenter(boundsCenter);

    markerClusterer = new MarkerClusterer(map, markers, markerclusterSettings);

}

/**
 * Enlève tous les markers présents sur la map
 */
export const removeMarkers = () => {
    if (markers) {
        markers.forEach(marker => marker.setMap(null))

        if (markerClusterer) {
            markerClusterer.clearMarkers();
        }

        markers = [];
    }
}

/**
 * Gère l'affichage des cards en fonction de la visibilité de son marker associé
 */
export const handleCardVisibility = () => {
    if (!markers) {
        return;
    }

    const bounds = map.getBounds();

    markers.map(marker => {
        const card = document.querySelector(`[js-card-id="${marker.ID}"]`);
        const masonryItem = getClosest(card, '.w-card');

        if (!masonryItem) {
            return;
        }

        if (bounds.contains(marker.getPosition())) {
            masonryItem.style.display = 'block'
        } else {
            masonryItem.style.display = 'none'
        }
    });

    initMasonry();
}

/**
 * Permet de réinitialiser le zoom et la position de la map
 */
export const resetZoomAndCenter = () => {
    if (map) {
        map.setZoom(defaultZoom);
        map.panTo(defaultCenter);
    }
}

/**
 * Actualise les tags des filtres
 * @param jsonObject
 * @param tagsFilters
 * @param choices
 */
export const refreshTagsFilters = (jsonObject, tagsFilters, choices) => {
    let newChoices = [];
    const selectedChoice = choices.getValue();

    choices.clearChoices();

    if (selectedChoice && selectedChoice.value === '' && selectedChoice.label === 'Votre sélection') {
        newChoices.push({value: '', label: 'Votre sélection', selected: true, disabled: true});
    } else  newChoices.push({value: '', label: 'Votre sélection', selected: false,  disabled: true});

    if (jsonObject) {
        const tags = Object.entries(jsonObject);

        tags.forEach((item) => {
            if (selectedChoice && selectedChoice.value === item[1].term['term_id'] && selectedChoice.label === item[1].term['name']) {
                newChoices.push({value: item[1].term['term_id'], label: item[1].term['name'], selected: true});
            } else newChoices.push({value: item[1].term['term_id'], label: item[1].term['name'], selected: false})
        })
    }

    if (selectedChoice && selectedChoice.value === '' && selectedChoice.label === 'Tout') {
        newChoices.push({value: 'all', label: 'Tout', selected: true});
    } else newChoices.push({value: 'all', label: 'Tout', selected: false});

    choices.setChoices(
        newChoices,
        'value',
        'label',
        true,
    )
}
