import {openRange, setInputRange } from "./_map-range";

const locationAutocomplete = () => {
    const regionInput = document.getElementById('region');
    const latInput = document.getElementById('lat');
    const lngInput = document.getElementById('lng');

    let changeEvent = new Event('change', { bubbles: true });

    let selected = false;

    let autocomplete = new google.maps.places.Autocomplete(
        regionInput,
        {
            types: ['(cities)'],
            componentRestrictions: {country: "fr"},
        }
    );

    autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();

        const region = place ? place.name : "";
        const lat = place ? place.geometry.location.lat() : "";
        const lng = place ? place.geometry.location.lng() : "";

        setTimeout(function() {
            regionInput.value = region;
            regionInput.setAttribute('value', region);
            regionInput.dispatchEvent(changeEvent);
        }, 200);

        latInput.setAttribute('value', lat);
        latInput.dispatchEvent(changeEvent);
        lngInput.setAttribute('value', lng);
        lngInput.dispatchEvent(changeEvent);

        setInputRange();

        selected = !!place;
    });

    regionInput.onfocus = function () {
        selected = false;
        openRange();
    };

    regionInput.onblur = function() {
        setTimeout(function() {
            if (!selected) {
                emptyInput();
            }
        }, 200)
    }

    function emptyInput()  {
        const formWrapper = document.querySelector('[js-livesearch-form]');
        const input = formWrapper.querySelectorAll("input[type='text']");

        input.forEach((el) => {
            el.value = ''
            el.setAttribute('value', '');
            el.dispatchEvent(changeEvent);
        });
    }
}

export default locationAutocomplete;
