import { geoFindMe } from "../utils/_store-locator";
import locationAutocomplete from "../components/map/_location-autocomplete";
import { mapRange } from '../components/map/_map-range'

const programSearchSubmit = document.querySelector('[js-redirect-to-map]');
const findMe = document.querySelector('[js-find-me]');

if (programSearchSubmit) {
    let targetBaseUrl = programSearchSubmit.getAttribute('href');

    programSearchSubmit.addEventListener('click', function (e) {
        e.preventDefault();
        updateUrl(targetBaseUrl)
    });
}

function updateUrl(targetBaseUrl) {
    let targetUrl = targetBaseUrl;
    let count = 0;
    const region = document.getElementById('region');
    const lat = document.getElementById('lat');
    const lng = document.getElementById('lng');
    const range = document.getElementById('range');
    const projet = document.querySelector('[js-projet]');
    const pieces = document.querySelector('[js-pieces]');

    if (region.getAttribute("value")) {
        let attributeName = count === 0 ? '?region=' :  '&region=';
        targetUrl = targetUrl + attributeName + region.getAttribute('value');
        count ++
    }
    if (lat.getAttribute("value")) {
        let attributeName = count === 0 ? '?lat=' :  '&lat=';
        targetUrl = targetUrl + attributeName + lat.getAttribute('value');
        count ++
    }
    if (lng.getAttribute("value")) {
        let attributeName = count === 0 ? '?lng=' :  '&lng=';
        targetUrl = targetUrl + attributeName + lng.getAttribute('value');
        count ++
    }
    if (range.getAttribute("value")) {
        let attributeName = count === 0 ? '?range=' :  '&range=';
        targetUrl = targetUrl + attributeName + range.getAttribute('value');
        count ++
    }
    if (projet.querySelector('option').getAttribute('value')) {
        let attributeName = count === 0 ? '?projet=' :  '&projet=';
        targetUrl = targetUrl + attributeName + projet.querySelector('option').getAttribute('value');
        count ++
    }
    if (pieces.querySelector('option').getAttribute('value')) {
        let attributeName = count === 0 ? '?pieces=' :  '&pieces=';
        targetUrl = targetUrl + attributeName + pieces.querySelector('option').getAttribute('value');
        count ++
    }

    programSearchSubmit.setAttribute('href', targetUrl);
    document.location.href = targetUrl;
}

function initMap() {
    locationAutocomplete();
    mapRange();

    findMe.addEventListener('click', geoFindMe)
}

window.initMap = initMap;
