let markerclusterSettings = {
    styles: [
        {
            height: 58,
            width: 58,
            url: "/app/themes/lumberjack/static/cluster.svg",
            textColor: '#ffffff',
        }
    ]
};

export {
    markerclusterSettings
}
