import Masonry from 'masonry-layout';

const initMasonry = () => {
    const masonry = document.querySelector('[js-masonry]');

    return new Masonry(masonry, {
        itemSelector: '.w-card',
        gutter: 24,
        percentPosition: true,
    });
}

export default initMasonry;
