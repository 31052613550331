class GoogleMap {
    constructor(options) {
        this.map = null;
        return this.createMap(options);
    }

    createMap(options) {
        this.map = new google.maps.Map(document.querySelector(options.mapContainerSelector), options);
        this.map.addMarkers = locations => this.addMarkers(locations);

        return this.map;
    }
}

export default (
    GoogleMap
);
