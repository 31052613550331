class CustomPin {
    constructor(options) {
        this.overlay = null;
        this.latlng = options.latlng;
        this.html = options.html;
        this.map = options.map;
        this.center = null;
        this.className = null;

        if(options.customCenterCoords) {
            this.center = options.customCenterCoords;
        }

        return this.createPin();
    }

    createPin() {
        this.overlay = new google.maps.OverlayView();
        this.overlay.setMap(this.map);
        this.overlay.draw = () => this.draw();
        this.overlay.onRemove = () => this.onRemove();
        this.overlay.getPosition = () => this.getPosition();
        this.overlay.getClassName = () => this.getClassName();
        this.overlay.getDraggable = () => this.getDraggable();
        this.overlay.addListener = (eventType, callback) => this.addListener(eventType, callback);

        return this.overlay;
    }

    createDiv() {
        this.div = document.createElement('div');
        this.className = 'custom-pin-' + this.createUID();

        this.div.classList.add(this.className);
        this.div.style.position = 'absolute';

        if (this.html) {
            this.div.innerHTML = this.html;
        }
        google.maps.event.addDomListener(this.div, 'click', event => {
            google.maps.event.trigger(this, 'click');
        });
    }

    addListener(eventType, callback) {
        var _this = this;

        document.addEventListener(eventType, function(event) {
            if (event.target.closest('.'+ _this.className)) {
                callback();
            }
        });
    }

    appendDivToOverlay() {
        const panes = this.overlay.getPanes();
        panes.overlayImage.appendChild(this.div);
    }

    positionDiv() {
        const point = this.overlay.getProjection().fromLatLngToDivPixel(this.latlng);

        if (point) {
            if(this.center !== null) {
                this.div.style.left = `${point.x - this.center.x}px`;
                this.div.style.top = `${point.y - this.center.y}px`;
            } else {
                this.div.style.left = `${point.x - (this.div.offsetWidth / 2)}px`;
                this.div.style.top = `${point.y - (this.div.offsetHeight / 2)}px`;
            }
        }
    }

    draw() {
        if (!this.div) {
            this.createDiv();
            this.appendDivToOverlay();
        }
        this.positionDiv();
    }

    onRemove() {
        if (this.div) {
            this.div.parentNode.removeChild(this.div);
            this.div = null;
        }
    }

    getPosition() {
        return this.latlng;
    }

    getClassName() {
        return this.className;
    }

    getDraggable() {}

    createUID(){
        var dt = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-y'.replace(/[xy]/g, function(c) {
            var r = (dt + Math.random()*16)%16 | 0;
            dt = Math.floor(dt/16);
            return (c=='x' ? r :(r&0x3|0x8)).toString(16);
        });
        return uuid;
    }
}

export default (
    CustomPin
);
