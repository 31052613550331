export const mapRange = () => {

    const range = document.querySelector('.range');
    const bubble = document.querySelector('.bubble');

    const submitRange = document.querySelector('[js-submit-range]');
    const deleteSearch = document.querySelector('[js-delete-search]');
    const closeRangeButton = document.querySelector('[js-close-range]');
    const mapRange = document.querySelector('[js-map-range]');

    if (range) {
        range.addEventListener('input', () => {
            setBubble(range, bubble);
        });
    }

    if (mapRange) {
        mapRange.addEventListener('transitionend', function() {
            if (! mapRange.classList.contains('show')) {
                mapRange.style.display = "none";
            } else {
                setBubble(range, bubble);
            }
        });
    }

    if (submitRange) {
        submitRange.addEventListener('click', () => {
            setInputRange();
            closeRange();
        });
    }

    if (deleteSearch) {
        deleteSearch.addEventListener('click', () => {
            closeRange();
        });
    }

    if (closeRangeButton) {
        closeRangeButton.addEventListener('click', closeRange);
    }

    function setBubble(range, bubble) {
        const val = range.value ? range.value : 50;
        const min = range.min ? range.min : 0;
        const max = range.max ? range.max : 200;

        // https://stackoverflow.com/questions/10756313/javascript-jquery-map-a-range-of-numbers-to-another-range-of-numbers
        const scale = (num, in_min, in_max, out_min, out_max) => {
            return ((num - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
        };

        const percentage = (100 * (val - min)) / (max - min);
        const bg = `linear-gradient(90deg, #5AB057 ${percentage}%, #BEC5C7 ${percentage +
        0.1}%)`;

        const rangeWidth = getComputedStyle(range).getPropertyValue("width");
        const labelWidth = getComputedStyle(bubble).getPropertyValue("width");
        const numWidth = +rangeWidth.substring(0, rangeWidth.length - 2);
        const numLabelWidth = +labelWidth.substring(0, labelWidth.length - 2);
        const left = val * (numWidth / max) - numLabelWidth / 2 + scale(val, min, max, 10, -10);

        range.style.background = bg;

        bubble.innerHTML = val +' km';
        bubble.style.left = `${left}px`;
    }
}

export const openRange = () => {
    const mapRange = document.querySelector('[js-map-range]');
    const findMe = document.querySelector('[js-find-me]');

    if (! mapRange.classList.contains('show')) {
        if (! findMe.classList.contains('hide')) {
            findMe.classList.add('hide');
        }

        mapRange.style.display = "block";

        setTimeout(() => {
            mapRange.classList.add('show');
        }, 250)
    }
}

export const closeRange = () => {
    const mapRange = document.querySelector('[js-map-range]');
    const findMe = document.querySelector('[js-find-me]');

    if (mapRange.classList.contains('show')) {
        mapRange.classList.remove('show');

        if (findMe.classList.contains('hide')) {
            setTimeout(() => {findMe.classList.remove('hide')}, 250);
        }
    }
}

export const setInputRange = () => {
    const input = document.getElementById('range');
    const range = document.querySelector('.range');
    let changeEvent = new Event('change', { bubbles: true });

    input.value = range.value;
    input.setAttribute('value', range.value);
    input.dispatchEvent(changeEvent);
};
